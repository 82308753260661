<script setup lang="ts">
import { isAuthenticated } from '#imports';
import SiteAPI from '~/api/SiteAPI';

const { locale, locales, setLocale } = useI18n();

type Locale = {
  name: string
  code: string
}

const items = computed(() => ([
  (locales.value as Locale[]).map(l => ({
    label: l.name,
    icon: '',
    click: () => actionSetLocale(l.code),
  })),
]));

function actionSetLocale(code: string) {
  setLocale(code);
  if (isAuthenticated()) {
    SiteAPI.setLocale(code);
  }
}
</script>

<template>
  <UDropdown :items="items" :popper="{ offsetDistance: 6, placement: 'bottom-end' }">
    <UChip inset :text="locale" size="xl" color="gray">
      <UButton variant="ghost" color="gray" icon="i-heroicons-language" padded />
    </UChip>
  </UDropdown>
</template>

<style scoped></style>

<script setup lang="ts">
const colorScheme = useColorMode();

const actionIcon = computed(() => {
  if (colorScheme.value === 'dark') {
    return 'i-heroicons-moon-16-solid';
  }
  return 'i-heroicons-sun';
});

function toggleColorMode() {
  colorScheme.preference = colorScheme.value === 'dark' ? 'light' : 'dark';
}
</script>

<template>
  <UButton variant="ghost" color="gray" :icon="actionIcon" @click="toggleColorMode" />
</template>
